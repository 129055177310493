var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsContainer',{attrs:{"variant":"narrow","loading":_vm.loading}},[_c('div',{staticClass:"vs-flex flex-nowrap vs-flex-col"},[_c('div',{staticClass:"vs-h700"},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.fromUrl.title'))+" ")]),_c('div',{staticClass:"vs-body-large vs-mt-2"},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.fromUrl.subtitle'))+" ")]),(_vm.is4Dem)?_c('span',{staticClass:"vs-body-large"},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.fromUrl.university'))+" "),_c('VsButton',{attrs:{"variant":"link","size":"large","url":(_vm.universityUrl + "/article/701-come-importare-i-contatti-e-leggere-lo-storico-importazioni#4"),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.fromUrl.universityLink'))+" ")]),_vm._v(". ")],1):_vm._e()]),_c('ValidationObserver',{ref:"importUrlForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('div',{staticClass:"vs-mt-8"},[_c('ValidationProvider',{attrs:{"vid":"apiUrl","name":_vm.$t('lists.importContacts.fromUrl.apiUrlLabel'),"rules":"required|link","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"placeholder":_vm.$t('lists.importContacts.fromUrl.apiUrlPlaceholder'),"caption":_vm.$t('lists.importContacts.fromUrl.apiUrlCaption'),"label":_vm.$t('lists.importContacts.fromUrl.apiUrlLabel'),"size":"large","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.apiUrl),callback:function ($$v) {_vm.apiUrl=$$v},expression:"apiUrl"}})]}}],null,true)})],1),_c('div',{staticClass:"vs-mt-6"},[_c('ValidationProvider',{attrs:{"vid":"root","name":_vm.$t('lists.importContacts.fromUrl.rootLabel'),"rules":"noSpaces","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"placeholder":_vm.$t('lists.importContacts.fromUrl.rootPlaceholder'),"caption":_vm.$t('lists.importContacts.fromUrl.rootCaption'),"label":_vm.$t('lists.importContacts.fromUrl.rootLabel'),"size":"large","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.root),callback:function ($$v) {_vm.root=$$v},expression:"root"}})]}}],null,true)})],1),_c('div',{staticClass:"vs-h400 vs-mt-8 vs-mb-6"},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.fromUrl.paginationLabel'))+" ")]),_c('div',[_c('VsCheckbox',{attrs:{"size":"large","labelHidden":"","text":_vm.$t('lists.importContacts.fromUrl.paginationText')},model:{value:(_vm.paginationActive),callback:function ($$v) {_vm.paginationActive=$$v},expression:"paginationActive"}})],1),(_vm.paginationActive)?_c('VsCard',{staticClass:"vs-mt-8"},[_c('div',{staticClass:"vs-h200 vs-text-grey-800 vs-mb-6"},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.fromUrl.paginationDesc'))+" ")]),_c('VsLabel',{attrs:{"size":"large"}},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.fromUrl.pageKeyLabel'))+" ")]),_c('div',{staticClass:"vs-grid vs-grid-cols-2 vs-mb-6 vs-gap-4"},[_c('ValidationProvider',{attrs:{"vid":"pageKey","name":_vm.$t('lists.importContacts.fromUrl.pageKeyPlaceholder'),"rules":"required|noSpaces","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"placeholder":_vm.$t('lists.importContacts.fromUrl.pageKeyPlaceholder'),"labelHidden":"","error":errors.length > 0,"errorMessage":errors[0],"caption":_vm.$t('lists.importContacts.fromUrl.pageKeyCaption'),"size":"large"},model:{value:(_vm.pageKey),callback:function ($$v) {_vm.pageKey=$$v},expression:"pageKey"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"pageValue","name":_vm.$t('lists.importContacts.fromUrl.pageValuePlaceholder'),"rules":"required|numeric|min_value:1","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"placeholder":_vm.$t('lists.importContacts.fromUrl.pageValuePlaceholder'),"labelHidden":"","variant":"number","error":errors.length > 0,"errorMessage":errors[0],"caption":_vm.$t('lists.importContacts.fromUrl.pageValueCaption'),"size":"large"},model:{value:(_vm.pageValue),callback:function ($$v) {_vm.pageValue=_vm._n($$v)},expression:"pageValue"}})]}}],null,true)})],1),_c('VsLabel',{attrs:{"size":"large"}},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.fromUrl.limitKeyLabel'))+" ")]),_c('div',{staticClass:"vs-grid vs-grid-cols-2 vs-mb-6 vs-gap-4 vs-mb-12"},[_c('ValidationProvider',{attrs:{"vid":"limitKey|noSpaces","name":_vm.$t('lists.importContacts.fromUrl.limitKeyPlaceholder'),"rules":"required|noSpaces","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"placeholder":_vm.$t('lists.importContacts.fromUrl.limitKeyPlaceholder'),"labelHidden":"","error":errors.length > 0,"errorMessage":errors[0],"caption":_vm.$t('lists.importContacts.fromUrl.limitKeyCaption'),"size":"large"},model:{value:(_vm.limitKey),callback:function ($$v) {_vm.limitKey=$$v},expression:"limitKey"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"limitValue","name":_vm.$t('lists.importContacts.fromUrl.limitValuePlaceholder'),"rules":"required|numeric|min_value:0","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"placeholder":_vm.$t('lists.importContacts.fromUrl.limitValuePlaceholder'),"labelHidden":"","variant":"number","error":errors.length > 0,"errorMessage":errors[0],"caption":_vm.$t('lists.importContacts.fromUrl.limitValueCaption'),"size":"large"},model:{value:(_vm.limitValue),callback:function ($$v) {_vm.limitValue=_vm._n($$v)},expression:"limitValue"}})]}}],null,true)})],1),_c('div',{staticClass:"vs-h200 vs-text-grey-800 vs-mb-6"},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.fromUrl.paginationResponseTitle'))+" ")]),_c('ValidationProvider',{attrs:{"vid":"responseTotalPageKey","name":_vm.$t('lists.importContacts.fromUrl.responseTotalPageKeyPlaceholder'),"rules":"required|noSpaces","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-6",attrs:{"placeholder":_vm.$t('lists.importContacts.fromUrl.responseTotalPageKeyPlaceholder'),"error":errors.length > 0,"errorMessage":errors[0],"label":_vm.$t('lists.importContacts.fromUrl.responseTotalPageKeyLabel'),"caption":_vm.$t('lists.importContacts.fromUrl.responseTotalPageKeyCaption'),"size":"large"},model:{value:(_vm.responseTotalPageKey),callback:function ($$v) {_vm.responseTotalPageKey=$$v},expression:"responseTotalPageKey"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"responsePageKey","name":_vm.$t('lists.importContacts.fromUrl.responsePageKeyPlaceholder'),"rules":"noSpaces","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-12",attrs:{"placeholder":_vm.pageKey || _vm.$t('lists.importContacts.fromUrl.responsePageKeyPlaceholder'),"label":_vm.$t('lists.importContacts.fromUrl.responsePageKeyLabel'),"error":errors.length > 0,"errorMessage":errors[0],"caption":_vm.$t('lists.importContacts.fromUrl.responsePageKeyCaption'),"size":"large"},model:{value:(_vm.responsePageKey),callback:function ($$v) {_vm.responsePageKey=$$v},expression:"responsePageKey"}})]}}],null,true)}),_c('div',{staticClass:"vs-h200 vs-text-grey-800 vs-mb-6"},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.fromUrl.paginationResponseOthers'))+" ")]),_c('ValidationProvider',{attrs:{"vid":"limitPage","name":_vm.$t('lists.importContacts.fromUrl.responseTotalPageValueLabel'),"rules":"numeric|min_value:0","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-12",attrs:{"placeholder":_vm.$t('lists.importContacts.fromUrl.responseTotalPageValuePlaceholder'),"variant":"number","error":errors.length > 0,"errorMessage":errors[0],"label":_vm.$t('lists.importContacts.fromUrl.responseTotalPageValueLabel'),"caption":_vm.$t('lists.importContacts.fromUrl.responseTotalPageValueCaption'),"size":"large"},model:{value:(_vm.responseTotalPageValue),callback:function ($$v) {_vm.responseTotalPageValue=_vm._n($$v)},expression:"responseTotalPageValue"}})]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"vs-mt-10"},[(_vm.isSynchronization)?_c('VsButton',{attrs:{"size":"large","disabled":_vm.loading},on:{"click":function($event){return passes(_vm.createSync)}}},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.fromFile.upload'))+" ")]):_c('VsButton',{attrs:{"size":"large","disabled":_vm.loading},on:{"click":function($event){return passes(_vm.createAndPreprocessImport)}}},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.fromFile.upload'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }